<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { packService } from '../../../../services/pack.service';
import { filesBaseUrl } from '../../../../constants/config';
import { customFunction } from '../../../../utils/customFunction';
import moment from 'moment';
import {
    required,
    minValue
} from "vuelidate/lib/validators";
import VsToast from '@vuesimple/vs-toast';

export default {
    page: {
        title: "Morceaux",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader },
    data() {
        return {
            packs: [],
            items: [],
            filesBaseUrl,
            title: "Morceaux",
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "created",
            sortDesc: true,
            fields: [
                { key: "libelle", label: 'Libellé', sortable: true },
                { key: "montant", label: "Montant", sortable: true },
                { key: "options", sortable: false, thClass: !this.verifAdmin() ? 'd-none' : '', tdClass: !this.verifAdmin() ? 'd-none' : '' }
            ],
            form: {
                libelle: "",
                montant: "",
            },
            submitted: false,
            showForm: false,
            isUpdate: false,
            updateId: null,
            datePublished: ''
        };
    },
    validations: {
        form: {
            libelle: { required },
            montant: { required, minValue: minValue(1) }
        },
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.packs.length;
        }
    },
    mounted() {
        this.getPacks();
        // Set the initial number of items
        this.totalRows = this.items.length;
    },
    methods: {
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        
        async getPacks() {
            this.packs = await packService.get();
        },
        verifAdmin() {
            return customFunction.verifAdmin();
        },

        hideForm() {
            this.showForm = false;
            this.submitted = false;
            this.isUpdate = false;
            this.form.libelle = '';
            this.form.montant = '';
        },
        formateDateForm(d) {
            return moment(d).format('YYYY-MM-DD');
        },
        async showUpdate(item) {
            this.showForm = true;
            this.isUpdate = true;
            this.updateId = item.id;
            this.form.libelle = item.libelle;
            this.form.montant = item.montant;
        },
        async formSubmit() {
            this.submitted = true
            this.$v.$touch();
            //console.log(this.form);
            if (!this.$v.$error) {
                const rep = this.isUpdate ? await packService.update(this.form, this.updateId) : await packService.create(this.form);

                if (rep >= 400) {

                    VsToast.show({
                        title: 'Veuillez ressayer',
                        message: 'Une erreur s\'est produite',
                        variant: 'danger',
                    })

                } else {

                    VsToast.show({
                        title: this.isUpdate ? 'Mise à jour effectué' : 'Enregistrement effectué',
                        message: '',
                        variant: 'success',
                    });

                    this.packs = await packService.get();
                    this.hideForm();
                }

            }
        },
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row mb-2">
            <div v-if="!showForm && verifAdmin()" class="col-md-8">
                <button @click="showForm = true" class="btn btn-primary">Ajouter</button>
            </div>
        </div>
        <form v-if="showForm" class="needs-validation mb-2" @submit.prevent="formSubmit">
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Libellé</label>
                        <input id="validationCustom01" v-model="form.libelle" type="text" class="form-control"
                            placeholder="Libellé" value="" :class="{
                                'is-invalid': submitted && $v.form.libelle.$error,
                            }" />
                        <div v-if="submitted && $v.form.libelle.$error" class="invalid-feedback">
                            <span v-if="!$v.form.libelle.required">Champs requis.</span>
                        </div>
                    </div>
                </div>
               
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="form-label" for="validationCustom01">Montant</label>
                        <input id="validationCustom01" v-model="form.montant" type="number" class="form-control"
                            placeholder="Montant" value="" :class="{
                                'is-invalid': submitted && $v.form.montant.$error,
                            }" />
                        <div v-if="submitted && $v.form.montant.$error" class="invalid-feedback">
                            <span v-if="!$v.form.montant.required">Champs requis.</span>
                        </div>
                    </div>
                </div>
            </div>



            <button v-if="!isUpdate" class="btn btn-primary" type="submit">Enregistrer</button>
            <button v-if="isUpdate" class="btn btn-primary" type="submit">Mettre à jour</button>
            <button @click="hideForm()" class="btn btn-secondary" style="margin-left: 3px;" type="submit">Annuler</button>
        </form>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title-desc"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show
                                        <b-form-select class="form-control form-control-sm form-select form-select-sm"
                                            v-model="perPage" size="sm" :options="pageOptions"></b-form-select>entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ml-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0 datatables">
                            <b-table :items="packs" :fields="fields" responsive="sm" :per-page="perPage"
                                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                                
                                <template v-slot:cell(montant)="r">
                                    <span class="badge bg-primary"> {{ r.item.montant | currencyFormat }}</span>
                                </template>
                               
                              
                                <template v-slot:cell(options)="r">
                                    <button v-if="verifAdmin()" @click="showUpdate(r.item)" title="Mettre à jour"
                                        class="btn btn-warning btn-sm"><i class="fa fa-edit"></i></button>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="rows"
                                            :per-page="perPage"></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>