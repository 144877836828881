import errorHandler from "./handler/erroHandler";
import axiosInstance from "./manager/manager.service";
import { baseUrl } from "../constants/config";

export const packService = {
    get,
    getById,
    create,
    update

};


async function getById(id) {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/packs/${id}`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function get() {
    try {
        let rets = await axiosInstance.get(`${baseUrl}/packs`);
        return rets.data;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function create(events) {
    try {
        let ret = await axiosInstance.post(`${baseUrl}/packs`, events);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}

async function update(events, id) {
    try {
        let ret = await axiosInstance.put(`${baseUrl}/packs/${id}`, events);
        return ret;
    } catch (error) {
        console.log("error");
        console.log(error);
        return errorHandler(error);
    }
}
